import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  useFetchAlertsQuery,
  useFetchDevicesForVehiclesQuery,
  useFetchDeviceGroupsQuery,
} from '../../Features/API/apiSlice';

const AlertsPage = () => {
  const {
    data: initialAlerts,
    error: alertsError,
    isLoading: isLoadingAlerts,
  } = useFetchAlertsQuery();
  const { data: vehiclesResponse, error: vehiclesError } = useFetchDevicesForVehiclesQuery();
  const { data: groups, error: groupsError } = useFetchDeviceGroupsQuery();
  const [alerts, setAlerts] = useState([]);
  const [groupedAlertsArray, setGroupedAlertsArray] = useState([]);
  const [newAlertIds, setNewAlertIds] = useState([]);

  useEffect(() => {
    if (initialAlerts) {
      const formattedAlerts = initialAlerts.map((alert) => ({
        id: alert.alert_id,
        ...alert,
        vehicle: alert.plate_number,
        // Additional mappings if needed
      }));
      setAlerts(formattedAlerts);
    }
  }, [initialAlerts]);

  useEffect(() => {
    // Group the alerts whenever `alerts` changes
    const groupedAlerts = alerts.reduce((groups, alert) => {
      const key = `${alert.type}-${alert.vehicle}-${alert.device_id}`;
      if (!groups[key]) {
        groups[key] = {
          type: alert.type,
          vehicle: alert.vehicle,
          device_id: alert.device_id,
          alerts: [],
        };
      }
      groups[key].alerts.push(alert);
      return groups;
    }, {});
    const groupedArray = Object.values(groupedAlerts);
    console.log('Grouped Alerts:', groupedArray);
    setGroupedAlertsArray(groupedArray);
  }, [alerts]);

  useEffect(() => {
    const eventSource = new EventSource('https://fleetcamagile.com/api/alerts/stream/');
    
    // Debugging: Log when the connection is opened
    eventSource.onopen = () => console.log('EventSource connection opened.');

    eventSource.onmessage = (event) => {
      try {
        const newAlert = JSON.parse(event.data);
        console.log('Received new alert:', newAlert);

        setAlerts((prevAlerts) => {
          const updatedAlert = {
            id: newAlert.alert_id,
            ...newAlert,
            vehicle: newAlert.plate_number,
            // Additional mappings if needed
          };
          // Add the new alert to the beginning of the array
          return [updatedAlert, ...prevAlerts];
        });

        setNewAlertIds((prevIds) => [newAlert.alert_id, ...prevIds]);
      } catch (error) {
        console.error('Failed to parse incoming alert:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource encountered an error:', error);
      
    };

    return () => {
      eventSource.close();
      console.log('EventSource connection closed.');
    };
  }, []);

  const columns = [
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'message', headerName: 'Alert Type', width: 150 },
    // ... other columns
  ];

  const getRowClassName = (params) => {
    const alertName = params.row.name.toLowerCase();
    if (/ignition off/i.test(alertName) || /security box/i.test(alertName) || /panic/i.test(alertName)) {
      return 'alert-p1'; // P1 alerts
    } else if (/zone in/i.test(alertName) || /zone out/i.test(alertName) || /overspeed/i.test(alertName)) {
      return 'alert-p2'; // P2 alerts
    } else if (/crash/i.test(alertName) || /door/i.test(alertName) || /ignition on/i.test(alertName)) {
      return 'alert-p3'; // P3 alerts
    }
    return '';
  };

  if (isLoadingAlerts) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (alertsError || vehiclesError || groupsError) {
    return <Typography color="error">Error fetching data: {alertsError?.message || vehiclesError?.message || groupsError?.message}</Typography>;
  }

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Alerts
        </Typography>
        {groupedAlertsArray.map((group, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel-${index}`}>
              <Typography variant="subtitle1">
                {group.type} - {group.vehicle} - {group.device_id}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={group.alerts}
                  columns={columns}
                  pageSize={5}
                  getRowClassName={getRowClassName}
                  sx={{
                    '& .MuiDataGrid-virtualScroller': {
                      '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                        border: '2px solid #f1f1f1',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    },
                    '& .MuiDataGrid-row:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                    '& .MuiDataGrid-row.alert-p1': {
                      bgcolor: 'error.light',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'error.main',
                        color: 'black',
                      },
                    },
                    '& .MuiDataGrid-row.alert-p2': {
                      bgcolor: 'warning.light',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'warning.main',
                        color: 'black',
                      },
                    },
                    '& .MuiDataGrid-row.alert-p3': {
                      bgcolor: 'info.light',
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'info.main',
                        color: 'black',
                      },
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Paper>
  );
};

export default AlertsPage;