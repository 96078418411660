// src/Features/API/apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for your Django backend
const BASE_URL = process.env.REACT_APP_API_URL;

// Function to get the stored authentication token
const getToken = () => localStorage.getItem('token'); // Retrieves the token from local storage

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      // Do not set 'Content-Type' here; let fetchBaseQuery handle it
      return headers;
    },
  }),
  tagTypes: [
    'DeviceGroup',
    'Vehicle',
    'Device',
    'Alert',
    'Event',
    'User',
    'UserGroup',
  ],
  endpoints: (builder) => ({
    fetchDeviceGroups: builder.query({
      query: () => 'device-groups/',
      providesTags: ['DeviceGroup'],
    }),
    createUserGroup: builder.mutation({
      query: (groupData) => ({
        url: '/auth/groups/',
        method: 'POST',
        body: groupData,
        headers: { 'Content-Type': 'application/json' }, // Set 'Content-Type' here if needed
      }),
      invalidatesTags: ['UserGroup'],
    }),
    updateUserGroup: builder.mutation({
      query: ({ groupId, ...groupData }) => ({
        url: `/auth/groups/${groupId}/`,
        method: 'PUT',
        body: groupData,
      }),
      invalidatesTags: ['UserGroup'],
    }),
    deleteUserGroup: builder.mutation({
      query: (groupId) => ({
        url: `/auth/groups/${groupId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserGroup'],
    }),
    fetchUsers: builder.query({
      query: () => '/auth/users/',
      providesTags: ['User'],
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: '/auth/users/',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...userData }) => ({
        url: `/auth/users/${userId}/`,
        method: 'PUT',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/auth/users/${userId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    resetPassword: builder.mutation({
      query: ({ username, email, newPassword }) => ({
        url: '/auth/reset-password/',
        method: 'POST',
        body: { username, email, newPassword },
      }),
    }),
    fetchVehiclesForGroups: builder.query({
      query: () => 'vehicles/',
      providesTags: ['Vehicle'],
    }),
    fetchDevicesForVehicles: builder.query({
      query: () => 'devices/',
      providesTags: ['Device'],
    }),
    fetchAlerts: builder.query({
      query: () => 'alerts/',
      providesTags: ['Alert'],
    }),
    fetchAlertsByDevice: builder.query({
      query: (device_id) => `alerts/device/${device_id}/`,
      providesTags: ['Alert'],
    }),
    updateClient: builder.mutation({
      query: ({ group_id, ...data }) => ({
        url: `device-groups/${group_id}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    deleteClient: builder.mutation({
      query: (group_id) => ({
        url: `device-groups/${group_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceGroup'],
    }),
    moveDeviceToGroup: builder.mutation({
      query: ({ device_id, new_group_id }) => ({
        url: `move-device/${device_id}/`,
        method: 'POST',
        body: { new_group_id },
      }),
      invalidatesTags: ['DeviceGroup', 'Device'],
    }),
    createEvent: builder.mutation({
      query: (formData) => ({
        url: '/events/',
        method: 'POST',
        body: formData,
        // Do not set headers here; fetchBaseQuery will handle 'Content-Type'
      }),
      invalidatesTags: ['Event'],
    }),
  }),
});

export const {
  useFetchDeviceGroupsQuery,
  useFetchVehiclesForGroupsQuery,
  useFetchDevicesForVehiclesQuery,  // New hook for fetching devices
  useFetchAlertsQuery,
  useFetchAlertsByDeviceQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useMoveDeviceToGroupMutation,  // New hook for moving devices
  useCreateEventMutation,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useFetchUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useResetPasswordMutation,
} = apiSlice;