import React from 'react';
import { Box, Grid, Typography, Paper, Fade, Container, useTheme, useMediaQuery } from '@mui/material';
import CustomCard from './CustomCard';
import LockIcon from '@mui/icons-material/Lock';
import LabelIcon from '@mui/icons-material/Label';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import Groups2Icon from '@mui/icons-material/Groups2';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const cardData = [
        {
            title: "Bureau Portal",
            description: "Welcome to the Bureau Monitoring Hub! This is the heart and soul of the application, where all the action happens. Keep tabs on everything in one place and ensure your operations are running smoothly.",
            icon: <BusAlertIcon sx={{ fontSize: 40 }} />,
            onClick: () => navigate('/bureau-dashboard')
        },
        {
            title: "TipLock",
            description: "Dive into all things Tiplock! Manage, monitor, and keep track of all Tiplock-related activities with ease. Whether it's oversight or hands-on adjustments, this is your command center for all Tiplock matters.",
            icon: <LockIcon sx={{ fontSize: 40 }} />
        },
        {
            title: "Exco",
            description: "Exclusively for our Directors: get a sneak peek into the inner workings. From high-level overviews to detailed insights, this section lets you stay informed and ahead of the curve.",
            icon: <Groups2Icon sx={{ fontSize: 40 }} />
        },
        {
            title: "Sales",
            description: "Attention Sales Executives: this is your go-to space for monitoring your clients' Bureau activities. Stay on top of your game by keeping a close eye on client performance and engagement, all in one convenient place.",
            icon: <AutoGraphIcon sx={{ fontSize: 40 }} />
        },
        {
            title: "Insurance",
            description: "For our Insurer partners: manage and monitor your clients' risk profiles effortlessly. Gain comprehensive insights to help you make better decisions, all designed to keep you in control of your clients' risk management.",
            icon: <LocalShippingIcon sx={{ fontSize: 40 }} />
        },
        {
            title: "Whitelabel",
            description: "For those companies that prefer to go solo—here's your dedicated space! Manage your operations independently without us holding your hand, but don't worry, we're always here if you need a little backup.",
            icon: <LabelIcon sx={{ fontSize: 40 }} />
        }
    ];

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
            <Container maxWidth="xl" sx={{ pt: 4, pb: 8 }}>
                <Paper elevation={3} sx={{ p: 4, mb: 4, borderRadius: 2, backgroundColor: theme.palette.background.paper }}>
                    <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
                        Welcome to the Admin Dashboard
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Manage and monitor all aspects of your Bureau operations from this central hub.
                    </Typography>
                </Paper>

                <Grid container spacing={3}>
                    {cardData.map((card, index) => (
                        <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }} key={card.title}>
                            <Grid item xs={12} sm={6} md={4} lg={4} >
                                <CustomCard
                                    title={card.title}
                                    description={card.description}
                                    icon={card.icon}
                                    onClick={card.onClick}
                                />
                            </Grid>
                        </Fade>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Dashboard;