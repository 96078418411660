import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Paper } from '@mui/material';

const columns = [
  { field: 'eventId', headerName: 'Event ID', width: 150 },
  { field: 'eventName', headerName: 'Event Name', width: 200 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'eventDate', headerName: 'Date', width: 150 },
];

const rows = [
  { id: 1, eventId: 'E001', eventName: 'Annual Meeting', type: 'Meeting', eventDate: '2023-11-01' },
  { id: 2, eventId: 'E002', eventName: 'Tech Conference', type: 'Conference', eventDate: '2023-12-15' },
  // Add more rows as needed
];

export default function EventGrid() {
  const isLoading = false; // Replace with actual loading state

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '& .MuiDataGrid-virtualScroller': {
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              border: '2px solid #f1f1f1',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          },
        }}
      />
    </Paper>
  );
}
