import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Components/LoginPage/Login';
import Dashboard from '../Components/AdminDashBoard/LandingPage';
import SettingsPage from '../Components/AdminDashBoard/SettingsPage';
import PrivateRoute from './PrivateRoute';
import ManageUsers from '../Components/AdminDashBoard/ManageUsers';
import ResetPasswordPage from '../Components/AdminDashBoard/ResetPasswordPage';
import UserGroups from '../Components/AdminDashBoard/UserGroups';
import BureauLandingPage from '../Components/BureauPortal/Landing';
import ManageClients from '../Components/AdminDashBoard/ManageClients';
import ManageVehicles from '../Components/AdminDashBoard/ManageVehicles';
import ManageDevices from '../Components/AdminDashBoard/ManageDevices';
import AlertsPage from '../Components/BureauPortal/AlertsPage';
import EventManagementPage from '../Components/BureauPortal/EventManagementPage';
import NotFound from '../Components/NotFound/NotFound';
import BureauPortalLayout from '../Components/Nav/BureauPortalLayout';
import AdminLayout from '../Components/Nav/AdminLayout';

const Router = ({ toggleMode }) => (
  <Routes>
    {/* Public Routes */}
    <Route path="/login" element={<Login />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />

    {/* Private Routes */}
    <Route element={<PrivateRoute />}>
      {/* Admin Routes */}
      <Route element={<AdminLayout toggleMode={toggleMode} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/manage-users" element={<ManageUsers />} />
        <Route path="/user-groups" element={<UserGroups />} />
        <Route path="/manage-clients" element={<ManageClients />} />
        <Route path="/manage-vehicles" element={<ManageVehicles />} />
        <Route path="/manage-devices" element={<ManageDevices />} />
        {/* Add additional admin routes here */}
      </Route>

      {/* Bureau Portal Routes */}
      <Route element={<BureauPortalLayout toggleMode={toggleMode} />}>
        <Route path="/alerts" element={<AlertsPage />} />
        <Route path="/bureau-dashboard" element={<BureauLandingPage />} />
        <Route path="/event-management" element={<EventManagementPage />} />
        {/* Add additional BureauPortal routes here */}
      </Route>
    </Route>

    {/* Catch-all Route for 404 */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Router;