import React, { useEffect, useState, useRef } from 'react';
import { Box, IconButton, TextField, Typography, Paper, useTheme } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FlagIcon from '@mui/icons-material/Flag';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CreateEventModal from './CreateEventModal';
import { useFetchDeviceGroupsQuery } from '../../Features/API/apiSlice';

const CustomTreeView = ({ currentUser }) => {
  const theme = useTheme();
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: groupsResponse, error: groupsError, isLoading: groupsLoading } = useFetchDeviceGroupsQuery();
  const treeViewRef = useRef(null);

  const [flaggedVehicles, setFlaggedVehicles] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedGroupTitle, setSelectedGroupTitle] = useState(null);
  const [openEventModal, setOpenEventModal] = useState(false);

  useEffect(() => {
    const savedFlags = localStorage.getItem('flaggedVehicles');
    if (savedFlags) {
      setFlaggedVehicles(JSON.parse(savedFlags));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('flaggedVehicles', JSON.stringify(flaggedVehicles));
  }, [flaggedVehicles]);

  const groupList = groupsResponse?.groups || [];

  const getItemId = (type, id) => `${type}-${id}`;

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleCollapseClick = () => {
    setExpandedItems([]);
  };

  const handleScrollToBottom = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = treeViewRef.current.scrollHeight;
    }
  };

  const handleScrollToTop = () => {
    if (treeViewRef.current) {
      treeViewRef.current.scrollTop = 0;
    }
  };

  const filteredGroups = groupList
    .map((group) => {
      const filteredVehicles = group.vehicles.filter((vehicle) =>
        vehicle.plate_number.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (group.title.toLowerCase().includes(searchQuery.toLowerCase()) || filteredVehicles.length > 0) {
        return {
          ...group,
          vehicles: filteredVehicles.length > 0 ? filteredVehicles : group.vehicles,
        };
      }
      return null;
    })
    .filter(Boolean);

  const handleFlagToggle = (vehicleId) => {
    setFlaggedVehicles(prev => {
      const currentState = prev[vehicleId] || 'grey';
      const nextState = {
        'grey': 'green',
        'green': 'yellow',
        'yellow': 'red',
        'red': 'grey'
      }[currentState];
      return { ...prev, [vehicleId]: nextState };
    });
  };

  const handleResetFlags = () => {
    setFlaggedVehicles({});
  };

  const handleMoreClick = (event, vehicle) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicle(vehicle);
    setSelectedGroupTitle(vehicle.group_title);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedVehicle(null);
  };

  const handleCreateEvent = () => {
    setOpenEventModal(true);
  };

  if (groupsLoading) return <Typography>Loading...</Typography>;
  if (groupsError) return <Typography color="error">Error fetching data</Typography>;

  return (
    <Box>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Search Groups or Vehicles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <Typography variant="subtitle2" color="textSecondary" sx={{ mr: 1 }}>
                  🔍
                </Typography>
              ),
            }}
          />
          <IconButton color="primary" onClick={handleResetFlags} sx={{ padding: '6px' }}>
            <FlagIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleCollapseClick} sx={{ padding: '6px' }}>
            <ExpandMoreIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleScrollToBottom} sx={{ padding: '6px' }}>
            <ChevronRightIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleScrollToTop} sx={{ padding: '6px' }}>
            <ChevronRightIcon sx={{ transform: 'rotate(180deg)' }} />
          </IconButton>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, maxHeight: '70vh', overflowY: 'auto' }} ref={treeViewRef}>
        <SimpleTreeView
          aria-label="vehicle tree"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
        >
          {filteredGroups.length > 0 ? (
            filteredGroups.map((group) => (
              <TreeItem
                key={getItemId('group', group.group_id)}
                itemId={getItemId('group', group.group_id)}
                label={
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {group.title}
                  </Typography>
                }
              >
                {group.vehicles.map((vehicle) => (
                  <TreeItem
                    key={getItemId('vehicle', vehicle.plate_number)}
                    itemId={getItemId('vehicle', vehicle.plate_number)}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <FlagIcon 
                            sx={{ 
                              mr: 1, 
                              color: flaggedVehicles[vehicle.plate_number] || 'grey',
                              cursor: 'pointer',
                              transition: 'color 0.3s',
                              '&:hover': { color: 'primary.main' },
                            }} 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFlagToggle(vehicle.plate_number);
                            }}
                          />
                          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                            {vehicle.plate_number} - {vehicle.plan}
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={(e) => handleMoreClick(e, vehicle)}
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    }
                  >
                    {vehicle.devices.map((device) => (
                      <TreeItem
                        key={getItemId('device', device.device_id)}
                        itemId={getItemId('device', device.device_id)}
                        label={
                          <Typography variant="caption">
                            {device.device_type}
                          </Typography>
                        }
                      >
                        <TreeItem
                          key={getItemId('device-id', device.device_id)}
                          itemId={getItemId('device-id', device.device_id)}
                          label={<Typography variant="caption">{`Device ID: ${device.device_id}`}</Typography>}
                        />
                      </TreeItem>
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))
          ) : (
            <Box sx={{ padding: 2 }}>
              <Typography variant="body2" color="textSecondary">
                No results found.
              </Typography>
            </Box>
          )}
        </SimpleTreeView>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleCloseMenu}>
          Edit Vehicle
        </MenuItem>
        <MenuItem onClick={handleCreateEvent}>Create Event</MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          New Test
        </MenuItem>
      </Menu>

      {openEventModal && (
        <CreateEventModal
          open={openEventModal}
          onClose={() => setOpenEventModal(false)}
          vehicle={selectedVehicle}
          groupTitle={selectedGroupTitle}
        />
      )}
    </Box>
  );
};

export default CustomTreeView;