import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Avatar, Box, Button, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Features/Authentication/authSlice';
import logo from '../Photos/FC Logo 2.png';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
}));

const Navbar = ({ toggleMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
    navigate('/login');
  };

  const menuItems = [
    { label: 'Settings', onClick: () => navigate('/settings'), icon: <SettingsIcon /> },
    { label: 'Profile', onClick: () => navigate('/profile'), icon: <AccountCircleIcon /> },
    { label: 'Toggle Theme', onClick: () => typeof toggleMode === 'function' ? toggleMode() : console.error('toggleMode is not a function'), icon: theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon /> },
    { label: 'Logout', onClick: handleLogoutClick, icon: <LogoutIcon /> },
  ];

  return (
    <StyledAppBar position="fixed" sx={{ borderRadius: 0 }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, cursor: 'pointer' }} onClick={handleLogoClick}>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
          <Typography variant="h6" component="div">
            Bureau Userport
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
         

          {isMobile ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar alt={user?.name} src={user?.avatar}>
                    {user?.firstName && user?.lastName ? `${user.firstName[0]}${user.lastName[0]}` : 'U'}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </>
          )}
        </Box>
        
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              {item.icon}
              <Typography sx={{ ml: 1 }}>{item.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;